































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ThemeOptions } from '@/types/ui'
import { RankingType } from '@/types/coinData'

@Component({
  name: 'GridHeader',
})
export default class GridHeader extends Vue {
  @Prop({ default: '100%' }) width!: string
  @Prop({ default: 'light' }) theme!: ThemeOptions
  @Prop({ default: 'top_20' }) rankingType!: RankingType
}
