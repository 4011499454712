

































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import GridHeader from '@/components/GridHeader.vue'
import GridFooter from '@/components/GridFooter.vue'
import {
  CoinDataInterface,
  PriceStreamInterface,
  TrendStreamInterface,
} from '@/types/coinData'
import { CurrencyInterface, CurrencyOptions } from '@/types/currency'
import { ThemeOptions } from '@/types/ui'
import { RankingType } from '@/types/coinData'
import { EmitEvents, ServerSideEventsPath } from '@/types/events'

import {
  GridOptions,
  ColDef,
  GridApi,
  ColumnApi,
  GridReadyEvent,
} from 'ag-grid-community'

@Component({
  name: 'GridCore',
  components: {
    AgGridVue,
    GridHeader,
    GridFooter,
  },
})
export default class GridCore extends Vue {
  @Prop({ default: 'USD' }) currencyCode!: CurrencyOptions
  @Prop({ default: 'light' }) theme!: ThemeOptions
  @Prop({ default: '100%' }) width!: string
  @Prop({ default: 'top_20' }) rankingType!: RankingType
  @Prop({ default: false }) hideDaily!: boolean
  @Prop({ default: false }) hideWeekly!: boolean
  @Prop({ default: '' }) utm!: string

  rowId = 'qc_key'
  gridId = 'grid'

  eventStreamUrl = process.env.VUE_APP_SSE_HOST_URL
  priceEventStream!: EventSource
  ptcChangeEventStream!: EventSource
  trendMeanEventStream!: EventSource

  columnDefs: ColDef[] = []
  rowData: CoinDataInterface[] = []

  gridOptions!: GridOptions

  api!: GridApi | unknown
  columnApi!: ColumnApi | unknown
  gridColumnApi!: ColumnApi | unknown

  currency: CurrencyInterface = {
    locale: 'en',
    currency_code: 'USD',
    conv_rate: 1,
  }

  ptcChangeMapper: any = {
    change_usd_24h: 'price24h',
    change_usd_7d: 'price1week',
  }

  created() {
    this.gridOptions = {}
    this.gridOptions.columnDefs = this.columnDefs
  }

  mounted() {
    this.api = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi

    this.$gtag.event(`Open Widget | ${window.location.href}`, {
      event_category: `Grid Widget`,
      event_label: 'render',
      value: 1,
    })

    /** Start Price Event Stream **/
    this.priceEventStream = new EventSource(
      this.eventStreamUrl + ServerSideEventsPath.priceBroadcast
    )

    /** Start Percent Change Event Stream **/
    this.ptcChangeEventStream = new EventSource(
      this.eventStreamUrl + ServerSideEventsPath.ptcChangeBroadcast
    )

    /** Start Trend Mean Change (qma_score) Event Stream **/
    this.trendMeanEventStream = new EventSource(
      this.eventStreamUrl +
        ServerSideEventsPath.indicatorBroadcast +
        '?indicator=TREND&trend_values=qma_score'
    )

    /** Price Change Indicator Stream Listener **/
    if (this.priceEventStream) {
      this.priceEventStream.addEventListener('message', (e: any) => {
        this.$root.$emit(EmitEvents.priceStream, JSON.parse(e.data))
      })
    }

    /** Percent Change Indicator Stream Listener **/
    if (this.ptcChangeEventStream) {
      this.ptcChangeEventStream.addEventListener('message', (e: any) => {
        this.$root.$emit(EmitEvents.ptcChangeStream, JSON.parse(e.data))
      })
    }

    /** Trend Mean Change (qma_score) Indicator Stream Listener **/
    if (this.trendMeanEventStream) {
      this.trendMeanEventStream.addEventListener('message', (e: any) => {
        this.$root.$emit(EmitEvents.trendMeanStream, JSON.parse(e.data))
      })
    }
  }

  /** Resizes Columns to Fit **/
  setGridWidth(): void {
    if (this.gridOptions.api) {
      const gridHtml: HTMLElement | null = document.getElementById(this.gridId)
      if (gridHtml !== null) {
        const gridWidth: number = gridHtml.offsetWidth

        if (this.gridOptions && this.gridOptions.columnApi) {
          let totalColsWidth = 0
          const allColumns = this.gridOptions.columnApi.getAllColumns()
          if (allColumns) {
            for (let i = 0; i < allColumns.length; i++) {
              const column: any = allColumns[i]
              if (column) {
                if (column.colDef.hide === false) {
                  totalColsWidth += column?.colDef.width
                }
              }
            }
            if (gridWidth > totalColsWidth) {
              this.gridOptions.api.sizeColumnsToFit()
            }
          }
        }
      }
    }
  }

  onGridReady = (params: GridReadyEvent): void => {
    this.api = params.api
    this.columnApi = params.columnApi

    /** Event listener  for price stream */
    this.$root.$on(EmitEvents.priceStream, (price: any) => {
      this.updateCoinPrice(price)
    })

    /** Event listener for daily and weekly percent change */
    this.$root.$on(EmitEvents.ptcChangeStream, (price: any) => {
      if (price.change_usd_24h || price.change_usd_7d) {
        this.updatePercentChange(price)
      }
    })

    /** Event listener for trend qma_score change */
    this.$root.$on(EmitEvents.trendMeanStream, (value: any) => {
      this.updateQMA(value)
    })
  }

  async beforeMount() {
    /** UTM is for Google Analytics Campaign Tracking **/
    let utmConfig = ''

    if (this.utm) {
      utmConfig = `?${this.utm}`
      console.log(utmConfig, 'it exists')
    }

    this.gridOptions = {
      suppressCellSelection: true,
      defaultColDef: {
        sortable: true,
        resizable: true,
        enableCellChangeFlash: true,
      },
    }

    this.gridOptions.getRowNodeId = (data: any) => {
      return data[this.rowId]
    }

    this.columnDefs = [
      {
        headerName: 'SYMBOL',
        field: 'qc_key',
        width: 93,
        headerTooltip: `Symbol Name`,
        cellStyle: { 'justify-content': 'flex-end' },
        cellRenderer(params: any, utm: string = utmConfig) {
          const iDiv = document.createElement('div')
          const image = document.createElement('img')
          image.setAttribute(
            'src',
            `https://quantifycrypto.s3-us-west-2.amazonaws.com/pictures/crypto-img/32/icon/${params.data.qc_key.toLowerCase()}.png`
          )
          image.setAttribute('class', 'qc-coin-icon')

          const link: any = document.createElement('a')
          link.disabled = true
          link.textContent = params.value
          link.setAttribute(
            'style',
            'text-decoration:underline; color: inherit'
          )
          link.setAttribute('class', 'coin-link')
          iDiv.appendChild(image)
          iDiv.appendChild(link)
          link.addEventListener(
            'click',
            (e: { preventDefault: () => void }) => {
              e.preventDefault()
              window.open(
                `https://quantifycrypto.com/coins/${params.data.qc_key}/${utm}`
              )
            }
          )

          return iDiv
        },
      },
      {
        headerName: 'PRICE',
        field: 'price_usd',
        valueFormatter: this.currencyFormatter,
        width: 90,
        headerTooltip: 'Live prices from crypto currency exchanges',
        cellStyle: { 'justify-content': 'flex-end' },
      },
      {
        headerName: '1D%',
        field: 'price24h',
        width: 65,
        headerTooltip: `% change compared to Bitcoin Price in the last 24 hours`,
        cellClass: 'grid-cell-centered ',
        valueFormatter: this.ptcChangeFormatter,
        hide: this.hideDaily,
        cellStyle: (params: any) => {
          const value: number = params.value

          if (value * 100 >= 0) {
            return { color: '#4caf50', 'background-color': 'transparent' }
          } else if (value * 100 < 0) {
            return { color: '#f44336', 'background-color': 'transparent' }
          }
        },
      },
      {
        headerName: '1W%',
        field: 'price1week',
        width: 65,
        headerTooltip: `% change compared to Bitcoin Price in the last week`,
        cellClass: 'grid-cell-centered ',
        valueFormatter: this.ptcChangeFormatter,
        hide: this.hideWeekly,
        cellStyle: (params: any) => {
          const value: number = params.value

          if (value * 100 >= 0) {
            return { color: '#4caf50', 'background-color': 'transparent' }
          } else if (value * 100 < 0) {
            return { color: '#f44336', 'background-color': 'transparent' }
          }
        },
      },
      {
        headerName: 'TREND',
        field: 'qma_score',
        width: 70,
        headerTooltip: 'Trend Summary Score',
        cellClass: 'grid-cell-centered',
        valueFormatter: this.trendFormatter,
        cellStyle: (params: any) => {
          const value: number = params.value

          if (value >= 0 && value <= 25) {
            return { color: '#f44336', 'background-color': 'transparent' }
          } else if (value > 25 && value <= 75) {
            return { color: '#ff9800', 'background-color': 'transparent' }
          } else if (value > 75 && value <= 100) {
            return { color: '#4caf50', 'background-color': 'transparent' }
          }
        },
      },
    ]

    try {
      const { data } = await this.axios.get(
        `/api/v1.0/widgets/grid?ranking_type=${this.rankingType}&currency=${this.currencyCode}`
      )

      this.rowData = data.data

      this.currency = data.currency
    } catch (error) {}
  }

  updateCoinPrice(data: PriceStreamInterface): void {
    try {
      if (this.gridOptions.api) {
        const rowNode = this.gridOptions.api.getRowNode(data.qc_key)
        if (rowNode) {
          rowNode.setDataValue(
            'price_usd',
            data.price_usd * this.currency.conv_rate
          )
        }
      }
    } catch (error) {}
  }

  updatePercentChange(data: Record<string, any>): void {
    try {
      if (this.gridOptions.api) {
        const rowNode = this.gridOptions.api.getRowNode(data.qc_key)
        if (rowNode) {
          let updateKey = null
          for (const [key] of Object.entries(data)) {
            if (key !== 'qc_key') {
              updateKey = key
            }
          }
          if (updateKey === 'change_usd_24h' || updateKey === 'change_usd_7d') {
            rowNode.setDataValue(
              this.ptcChangeMapper[updateKey],
              data[updateKey]
            )
          }
        }
      }
    } catch (error) {}
  }

  updateQMA(data: TrendStreamInterface): void {
    try {
      if (this.gridOptions.api) {
        const rowNode = this.gridOptions.api.getRowNode(data.value.qc_key)
        if (rowNode) {
          rowNode.setDataValue(data.value.key, data.value.value)
        }
      }
    } catch {}
  }

  currencyFormatter(params: any) {
    const value = params.value
    if (value > 100) {
      return `${new Intl.NumberFormat(this.currency.locale, {
        currency: this.currency.currency_code,
        style: 'currency',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value)}`
    } else {
      return `${new Intl.NumberFormat(this.currency.locale, {
        currency: this.currency.currency_code,
        style: 'currency',
        maximumFractionDigits: 4,
        minimumFractionDigits: 4,
      }).format(value)}`
    }
  }

  trendFormatter(params: any) {
    return `${params.value}%`
  }

  ptcChangeFormatter(params: any) {
    return `${(params.value * 100).toFixed(2)}%`
  }

  beforeDestroy() {
    if (this.priceEventStream) {
      this.priceEventStream.close()
    }

    if (this.ptcChangeEventStream) {
      this.ptcChangeEventStream.close()
    }

    if (this.trendMeanEventStream) {
      this.trendMeanEventStream.close()
    }
  }
}
