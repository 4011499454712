



































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ThemeOptions } from '@/types/ui'

@Component({
  name: 'GridFooter',
})
export default class GridFooter extends Vue {
  @Prop({ default: '100%' }) width!: string
  @Prop({ default: 'light' }) theme!: ThemeOptions
  @Prop({ default: '' }) utm!: string
}
