export enum EmitEvents {
  priceStream = 'price-stream',
  ptcChangeStream = 'ptc-change-stream',
  trendMeanStream = 'trend-mean-stream',
}

export enum ServerSideEventsPath {
  priceBroadcast = '/price-broadcast',
  ptcChangeBroadcast = '/ptc-change-broadcast',
  indicatorBroadcast = '/indicator-broadcast',
}
