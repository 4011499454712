var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qc-row",style:({
    width: ("" + _vm.width),
    height: '28px',
    textAlign: 'left',
    paddingLeft: '10px',
    backgroundColor: _vm.theme == 'dark' ? '#181d1f' : '#F8F8F8',
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderBottomStyle: 'none',
    borderColor: _vm.theme == 'dark' ? 'rgb(255, 255, 255, 0.12)' : '#babfc7',
    color: _vm.theme == 'dark' ? '#fff' : '#181D1F',
    borderTopLeftRadius: '0.25em',
    borderTopRightRadius: '0.25em',
    alignItems: 'center',
  })},[_c('div',{staticClass:"qc-col"},[_c('div',{style:({
        float: 'left',
        marginTop: '4px',
        fontFamily: 'Roboto, sans-serif !important',
        fontWeight: '600',
        fontSize: '.9rem',
      })},[_vm._v(" "+_vm._s(_vm.rankingType === 'top_20' ? 'Top 20 Coins' : 'Daily Winners/Losers')+" ")]),_c('div',{staticClass:"tooltip",style:({
        float: 'right',
        paddingRight: '10px',
        marginTop: '4px',
        letterSpacing: '.009375em',
      })},[_c('svg',{staticStyle:{"width":"17px","height":"17px"},attrs:{"viewBox":"0 0 22 22"}},[_c('path',{attrs:{"fill":"currentColor","d":"M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z"}})]),_c('span',{staticClass:"tooltiptext",style:({
          backgroundColor: _vm.theme == 'dark' ? '#222628' : '#f8f8f8',
          borderStyle: 'solid',
          borderColor: _vm.theme == 'dark' ? '#68686e' : '#babfc7',
          borderWidth: '1px',
          fontFamily: 'Roboto, sans-serif !important',
        })},[_vm._v("Click on coin symbol for more information.")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }