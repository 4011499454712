














import { Component, Vue, Prop } from 'vue-property-decorator'
import GridCore from './GridCore.vue'
import { CurrencyOptions } from '@/types/currency'
import { ThemeOptions } from '@/types/ui'
import { RankingType } from '@/types/coinData'

@Component({
  name: 'App',
  components: {
    GridCore,
  },
})
export default class App extends Vue {
  @Prop({ default: 'USD' }) currencyCode!: CurrencyOptions
  @Prop({ default: 'light' }) theme!: ThemeOptions
  @Prop({ default: '380px' }) width!: string
  @Prop({ default: 'top_20' }) rankingType!: RankingType
  @Prop({ default: false }) hideDaily!: boolean
  @Prop({ default: false }) hideWeekly!: boolean
  @Prop({ default: '' }) utm!: string
}
