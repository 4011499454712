var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    width: ("" + _vm.width),
    height: '24px',
    fontSize: '11px',
    fontWeight: '400',
    letterSpacing: '.009375em',
    backgroundColor: _vm.theme == 'dark' ? '#181d1f' : '#F8F8F8',
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderTopStyle: 'none',
    borderColor: _vm.theme == 'dark' ? 'rgb(255, 255, 255, 0.12)' : '#babfc7',
    color: _vm.theme == 'dark' ? '#fff' : '#000',
    borderBottomLeftRadius: '0.25em',
    borderBottomRightRadius: '0.25em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })},[_c('a',{staticStyle:{"font-size":"0.72rem","font-weight":"500","text-decoration":"none"},attrs:{"id":"quantify-link","href":("https://quantifycrypto.com/" + _vm.utm),"target":"_blank"}},[_vm._v("Powered By Quantify Crypto")])])}
var staticRenderFns = []

export { render, staticRenderFns }